import React from 'react';
import '../App.css';
import Santa from "../assets/santaSleigh.png";
import Navbar from '../constants/Navbar';

const AnimatedImage = () => {
    return (
        <>
            <Navbar className='mt-8' />
            <div className="relative mt-5 flex justify-center items-center overflow-hidden" style={{ height: '300px' }}>

                {/* Gradient Text with Bounce Animation */}
                <h1 className="absolute text-4xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-red-500 via-pink-500 to-yellow-500 animate-bounce">
                    Merry Christmas
                </h1>

                {/* Animated Image with Zoom, Move, and Slide Effect */}
                <div
                    className="absolute opacity-90 hover:opacity-100  duration-300"
                    style={{
                        animation: 'moveImage 6s linear infinite, zoomImage 4s alternate infinite, slideImage 5s linear infinite ',
                        width: '200px',
                    }}
                >
                    <img
                        src={Santa}
                        alt="Santa's Sleigh"
                        style={{ width: '100%', height: 'auto', display: 'block' }}
                    />
                </div>
            </div>

            {/* Persuasive Paragraph with Gradient Text */}
            <p className="text-center text-lg font-800 text-transparent bg-clip-text bg-gradient-to-r from-red-500 via-pink-500 to-yellow-500 animate-bounce">
                Don't miss out on our exclusive Christmas deal! <span className="font-800 text-yellow-500">Hurry, limited time offer!</span>
            </p>

        </>
    );
};

export default AnimatedImage;
