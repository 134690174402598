import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDesktop, faMobileAlt, faPaintBrush, faChartLine, faCloud, faDatabase, faRobot, faImage, faBullhorn } from '@fortawesome/free-solid-svg-icons';
import Navbar from '../constants/Navbar';
import Footer from '../constants/Footer';
import { useNavigate } from 'react-router-dom';
import ProgressSlider from '../constants/ProgressSlider';
import AnimatedImage from '../components/AnimatedImage';

const ProductsSection = () => {
    const navigate = useNavigate();

    const products = [
        {
            title: 'Websites',
            icon: faDesktop,
            description: `Build a robust online presence with a professional, SEO-friendly website 
                          tailored to your brand and audience. Ensure optimal user experience, 
                          speed, and scalability for long-term success.`,
            features: [
                'Custom and responsive designs for all devices',
                'Search engine optimization (SEO) to improve visibility',
                'Integration with tools like Google Analytics, CRMs, and e-commerce platforms',
                'CMS for seamless content updates and management',
                'Performance optimization for fast loading times',
                'Secure HTTPS implementations and data protection',
            ],
            color: 'text-blue-500',
        },
        {
            title: 'UI/UX Designing',
            icon: faPaintBrush,
            description: `Deliver intuitive and aesthetically pleasing designs focused on user engagement 
            and satisfaction. Perfect for startups, businesses, and healthcare solutions.`,
            features: [
                'Custom wireframes and high-fidelity prototypes',
                'Interactive user testing and feedback loops',
                'Design systems for scalable and consistent branding',
                'Mobile-first, responsive interfaces for diverse screens',
                'Optimized user workflows for complex systems like healthcare or research tools',
                'Accessibility-compliant designs (WCAG, ADA standards)',
            ],
            color: 'text-green-500',
        },
        {
            title: 'Mobile Applications',
            icon: faMobileAlt,
            description: `Transform your ideas into feature-rich, high-performance mobile apps 
            that cater to Android, iOS, or both.`,
            features: [
                'Native development for iOS (Swift) and Android (Kotlin)',
                'Cross-platform solutions using React Native or Flutter',
                'Secure and HIPAA-compliant apps for sensitive industries',
                'Post-launch maintenance, updates, and bug fixes',
                'In-app payment and subscription integrations',
                'Comprehensive ASO (App Store Optimization) for better app discoverability',
            ],
            color: 'text-red-500',
        },
        {
            title: 'Product Marketing',
            icon: faBullhorn,
            description: `Accelerate your product's success with comprehensive marketing strategies designed 
                          to drive growth and maximize customer engagement.`,
            features: [
                'Go-to-market strategies for new product launches',
                'Digital marketing campaigns across social, search, and email',
                'Content creation, including blogs, videos, and promotional materials',
                'Market research and competitor analysis',
                'Performance tracking and analytics for campaign ROI',
                'Customer journey mapping and targeted audience segmentation',
            ],
            color: 'text-orange-500',
        },


        {
            title: 'Graphic Design',
            icon: faImage,
            description: `Elevate your brand identity and marketing efforts with stunning, on-brand graphic designs.`,
            features: [
                'Complete brand identity packages (logos, color schemes, typography)',
                'Print-ready marketing materials such as brochures and flyers',
                'Custom illustrations and infographics for publications',
                'Social media banners and post templates',
                'Designs for events, packaging, and trade shows',
                'Consistent visual storytelling across platforms',
            ],
            color: 'text-pink-500',
        },
        {
            title: 'Advanced Business Analytics',
            icon: faChartLine,
            description: `Transform your data into powerful insights with advanced analytics, visualization, and predictive modeling to drive informed business decisions.`,
            features: [
                'Custom dashboards designed around your business KPIs for focused insights',
                'Integration with top analytics platforms like Power BI and Tableau for seamless reporting',
                'Real-time data updates and live monitoring for accurate decision-making',
                'Predictive analytics for forecasting future trends and optimizing strategies',
                'Advanced reporting features with export options for in-depth analysis',
                'Big data processing and analytics using cloud-based pipelines for scalability',
            ],
            color: 'text-indigo-500',
        },

        {
            title: 'AI & Machine Learning Solutions',
            icon: faRobot,
            description: `Transform your business with advanced AI and machine learning technologies designed to enhance decision-making, optimize processes, and unlock new insights.`,
            features: [
                'Custom machine learning models tailored to your specific needs',
                'Natural language processing (NLP) for analyzing and extracting value from text data',
                'Computer vision for advanced image recognition and classification',
                'Seamless integration with your existing systems and platforms',
                'Continuous model optimization for improved performance and accuracy',
                'AI-driven recommendations and personalized user experiences',
            ],
            color: 'text-teal-500',
        },

        {
            title: 'Cloud Storage & Infrastructure Solutions',
            icon: faCloud,
            description: `Leverage scalable, secure, and cost-efficient cloud storage and infrastructure to drive digital transformation and ensure business continuity.`,
            features: [
                'Cloud storage setup and migration (AWS, Azure, GCP) tailored to your needs',
                'Infrastructure as Code (IaC) implementation using Terraform or CloudFormation for automation',
                'Containerization with Docker and orchestration using Kubernetes for optimized deployment',
                'Reliable disaster recovery and backup solutions for business continuity',
                'Cloud cost optimization strategies to maximize efficiency and minimize expenses',
                'Comprehensive monitoring and security solutions to protect your cloud infrastructure',
            ],
            color: 'text-gray-500',
        },

        {
            title: 'Backend Development',
            icon: faDatabase,
            description: `Power your applications with secure, scalable, and efficient server-side solutions.`,
            features: [
                'Custom API development (REST and GraphQL)',
                'Database architecture and management (SQL, NoSQL)',
                'Authentication and role-based access control',
                'Scalable microservices architecture with Docker and Kubernetes',
                'Real-time communication with WebSockets',
                'Robust testing and debugging processes for reliability',
            ],
            color: 'text-yellow-500',
        },
    ];



    return (
        <>
            <Navbar className="mb-16" />
            <AnimatedImage />
            <div className="py-16 px-4 mt-5 sm:px-6 lg:px-8 xl:px-10 bg-[radial-gradient(100%_50%_at_50%_0%,rgba(0,188,212,0.13)_0,rgba(0,188,212,0)_50%,rgba(0,188,212,0)_100%)] selection:bg-cyan-300 selection:text-cyan-900">
                {/* Heading */}
                <div className="text-center mb-12">
                    <h1 className="text-4xl text-gray-700 font-semibold text-center p-5 mb-10">
                        Our Services
                    </h1>
                    <p className="mt-4 text-lg text-gray-600 max-w-2xl mx-auto">
                        We offer a diverse range of services tailored to meet the needs of businesses of all sizes.
                    </p>
                </div>

                {/* Products Grid */}
                <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                    {products.map((product, index) => (
                        <div
                            key={index}
                            className="bg-white p-6 rounded-xl shadow-lg flex flex-col h-full"
                        >
                            {/* Icon Section */}
                            <div className="h-32 flex justify-center items-center">
                                <FontAwesomeIcon icon={product.icon} className={`text-6xl ${product.color}`} />
                            </div>
                            {/* Title Section */}
                            <h3 className="text-2xl font-semibold mt-4 text-center text-gray-800">
                                {product.title}
                            </h3>
                            {/* Description Section */}
                            <p className="mt-2 text-gray-600 font-800 p-5 text-center">
                                {product.description}
                            </p>
                            {/* Features List Section */}
                            <ul className="mt-4 space-y-2 text-gray-700 text-sm list-disc list-inside">
                                {product.features.map((feature, i) => (
                                    <li key={i}>{feature}</li>
                                ))}
                            </ul>
                            {/* Button Section */}
                            <div className="mt-auto flex justify-center">
                                <button
                                    className="py-2 px-6 p-2 bg-black text-white font-600 rounded-md hover:bg-black  transition w-full sm:w-auto"
                                    onClick={() => navigate('/pricing')}
                                >
                                    Explore
                                </button>
                            </div>
                        </div>

                    ))}
                </div>
            </div>
            <ProgressSlider />
            <Footer />
        </>
    );
};

export default ProductsSection;
